import { useDispatch, useSelector } from 'react-redux';
import classes from './Footer.module.css';
import { idleOff, idleOn, setApp, setLanguage } from './../_redux/appSlice';
import { useEffect, useState } from 'react';

export default function Footer() {

  const dispatch = useDispatch();
  const app = useSelector((state) => state.app?.app);
  const idleMode = useSelector((state) => state.app?.idleMode);
  const language = useSelector((state) => state.app?.language);
  const activeEntryId = useSelector((state) => state.app?.activeEntry);
  const [activeXPos, setActiveXPos] = useState(0);
  const introEnded = useSelector((state) => state.app?.introEnded);

  useEffect(() => {
    console.log("App loaded")
   },[]);

  let activeEntry = window.DB?.find(activeEntryId);
  let avaialbleTypes = [];
  activeEntry?.subentries.forEach(se => {
    if(!avaialbleTypes.includes(se.type))
      avaialbleTypes.push(se.type);
  });

  useEffect(()=>{
    if(activeEntryId){
      let el = document.getElementById("navEntry_"+activeEntryId);
      if(el!=null){
        let rect = el.getBoundingClientRect();
        setActiveXPos(rect.x+rect.width/2);
      }
    }else{

    }
  },[activeEntryId])


  if(window.DB == null)
   return <></>;
  return (
    <div className={classes.root + " "+ (!activeEntryId && !idleMode?classes.intro:'') +(introEnded?classes.introEnded:'') } style={{'--xPos':activeXPos+"px"}}>
      <div className={classes.rows}>
        <div className={classes.row + ' work ' + (avaialbleTypes.indexOf('work')>-1?classes.active:'')}>
          <div className={classes.bg}></div>
          <div className={classes.thumb}>
            <div className={classes.left}>
              <div className="icon" style={{'--bg':'url(/assets/icon_work.png)'}}></div>
            </div>
            <div className={classes.right}>{window.DB?.lang.work.Get()}</div>
          </div>
        </div>
        <div className={classes.row + ' technology ' +(avaialbleTypes.indexOf('technology')>-1?classes.active:'')}>
          <div className={classes.bg}></div>
          <div className={classes.thumb}>
            <div className={classes.left}>
              <div className="icon" style={{'--bg':'url(/assets/icon_technology.png)'}}></div>
            </div>
            <div className={classes.right}>{window.DB?.lang.technology.Get()}</div>
          </div>
        </div>
        <div className={classes.row + ' environment ' +(avaialbleTypes.indexOf('environment')>-1?classes.active:'')}>
          <div className={classes.bg}></div>
          <div className={classes.thumb}>
            <div className={classes.left}>
              <div className="icon" style={{'--bg':'url(/assets/icon_environment.png)'}}></div>
            </div>
            <div className={classes.right}>{window.DB?.lang.environment.Get()}</div>
          </div>
        </div>

      </div>
      {idleMode && <div className={classes.idle}>
        <h1>{window.DB.idle.title.Get()}</h1>
        <h2>{window.DB.idle.text.Get()}</h2>
      </div>
      }
    </div>
  );
}

