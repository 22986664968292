import { useDispatch, useSelector } from 'react-redux';
import classes from './Stage.module.css';
import { idleOff, idleOn, setApp, setLanguage } from './../_redux/appSlice';
import { useEffect } from 'react';
import NaviationMain from './NaviationMain/NaviationMain';
import ContentPlayer from './ContentPlayer/ContentPlayer';
import IntroPlayer from './IntroPlayer/IntroPlayer';

export default function Stage() {

  const dispatch = useDispatch();
  const app = useSelector((state) => state.app?.app);
  const idleMode = useSelector((state) => state.app?.idleMode);
  const activeEntryId = useSelector((state) => state.app?.activeEntry);
  const language = useSelector((state) => state.app?.language);

  useEffect(() => {

  },[]);



  let activeEntry = window.DB?.find(activeEntryId);


  let avaialbleTypes = [];
  activeEntry?.subentries.forEach(se => {
    if(!avaialbleTypes.includes(se.type))
      avaialbleTypes.push(se.type);
  });




  return (
    <div className={classes.root}>
      <IntroPlayer></IntroPlayer>
      <NaviationMain></NaviationMain>
      <div className={classes.title} key={activeEntryId}>{activeEntry?.title.Get()}</div>
      <ContentPlayer></ContentPlayer>
    </div>
  );
}

