import { createSlice } from '@reduxjs/toolkit'
import { SetLanguage } from '../LanguageString';


export const appSlice = createSlice({
  name: 'app',
  initialState: {
    app:null,
    language:"de",
    appDb:null,
    idleMode:false,
  },
  reducers: {
    toggleLanguage: (state) => {
      if(state.language === "de")
        state.language = "en";
      else if(state.language === "en")
        state.language = "nl";
      else
        state.language = "de";

      document.documentElement.setAttribute('lang', state.language);
      SetLanguage(state.language);
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
      document.documentElement.setAttribute('lang', state.language);
      SetLanguage(state.language);
    },

    setApp: (state, action) => {
      state.app = action.payload;
    },
    setDbHash: (state, action) =>{
      state.appDbHash = action.payload;
    },

    idleOn: (state) => {
      state.idleMode = true;
    },
    idleOff: (state) => {
      state.idleMode = false;
    },

    setActiveEntry:(state, action) =>{
      state.activeEntry = action.payload;

    },
    setActiveContent:(state, action) =>{
      state.activeContent = action.payload;

    },
    setIntroEnded:(state, action) =>{
      state.introEnded = action.payload;

    }

  },
})
export const {setApp, setLanguage, toggleLanguage, setDbHash, idleOff, idleOn, setActiveEntry, setActiveContent, setIntroEnded} = appSlice.actions

export default appSlice.reducer