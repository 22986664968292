import { LanguageString } from "./LanguageString";


let db = null;

export function ParseDB(raw){
  let db = new DB(raw);

  db.timeout = (raw.timeout || 10) * 1000;
  db.lang = {};
  for(let i in raw.lang){
    db.lang[i] = new LanguageString(raw.lang[i]);
  }



  return db;
}

let inc=0;

const getNextId = () =>{
  inc++;
  return inc;
}

class DB{
  constructor(raw){



    this.idle = {title:new LanguageString(raw.idle.title), text:new LanguageString(raw.idle.text)};

    this.intro = {title:new LanguageString(raw.intro.title), video:new LanguageString(raw.intro.video)};
    this.imprint = {title:new LanguageString(raw.imprint.title), image:new LanguageString(raw.imprint.image), logo:new LanguageString(raw.imprint.logo)};

    this.entries = [];
    for(let i in raw.entries){
      this.entries.push(new Entry(raw.entries[i]));
    }


    console.log("Parsed DB", this);
  }

  find(id){
    for(let i in this.entries){
      if(this.entries[i].id === id)
        return this.entries[i];
      for(let j in this.entries[i].subentries){
        if(this.entries[i].subentries[j].id === id)
          return this.entries[i].subentries[j];
      }
    }
    return null;
  }
}

class Entry{
  constructor(raw){
    this.title = new LanguageString(raw.title);
    this.audio = new LanguageString(raw.audio);
    this.intro = new LanguageString(raw.intro);
    this.id = getNextId();

    this.subentries = [];
    for(let i in raw.subentries){
      this.subentries.push(new SubEntry(raw.subentries[i]));
    }
  }
}
class SubEntry{
  constructor(raw){
    this.title = new LanguageString(raw.title);
    this.id = getNextId();
    this.type = raw.type;
    this.position = {x:raw.position.x||1, y:raw.position.y||1};
    this.audio = new LanguageString(raw.audio);
    this.media = [];
    for(let i in raw.media){
      this.media.push(new LanguageString(raw.media[i]));
    }
  }
}


