import { useDispatch, useSelector } from 'react-redux';
import classes from './NaviationMain.module.css';
import { idleOff, idleOn, setActiveEntry, setApp, setLanguage, toggleLanguage } from './../../_redux/appSlice';
import { useEffect, useState } from 'react';
import NavigationSub from '../NavigationSub/NavigationSub';

export default function NaviationMain() {

  const dispatch = useDispatch();


  const app = useSelector((state) => state.app?.app);
  const idleMode = useSelector((state) => state.app?.idleMode);
  const activeEntryId = useSelector((state) => state.app?.activeEntry);

  const language = useSelector((state) => state.app?.language);
  const introEnded = useSelector((state) => state.app?.introEnded);

  if(window.DB == null)
   return <></>;
  return (
    <div className={classes.root}>
      <div className={classes.entries + " "+ (activeEntryId?classes.activated:'') + " "+(introEnded?classes.introEnded:'')}>
        {window.DB?.entries?.map((entry, index) =>
          <div key={index} id={"navEntry_"+entry.id} style={{'--x':index/window.DB?.entries.length}} className={activeEntryId===entry.id?classes.active:''}>

            <div className={classes.label}>
              {entry.title.Get()}
            </div>
            <div className={classes.icon+ " icon"} style={{'--bg':`url(/assets/nav_entry_active.png)`}} onClick={()=>dispatch(setActiveEntry(entry.id))}>
              <div>{index+1}</div>
            </div>
            <NavigationSub entries={entry?.subentries} className={classes.subEntries}></NavigationSub>
          </div>
          )}
          <div className={classes.imprint + " " + classes.small + " "+(activeEntryId==null?classes.active:'')}  style={{'--x':(.8/window.DB?.entries.length)}} >
            <div className={classes.label}>
              {window.DB?.intro.title.Get()}
            </div>
            <div className={classes.icon+ " icon"} style={{'--bg':`url(/assets/nav_entry_active.png)`}} onClick={()=>dispatch(setActiveEntry(null))}>
              <div></div>
            </div>
          </div>

          <div className={classes.imprint + " " + classes.small + " "+(activeEntryId=="imprint"?classes.active:'')}  style={{'--x':(1 - 1.2/window.DB?.entries.length)}} >
            <div className={classes.label}>
              {window.DB?.imprint.title.Get()}
            </div>
            <div className={classes.icon+ " icon"} style={{'--bg':`url(/assets/nav_entry_active.png)`}} onClick={()=>dispatch(setActiveEntry("imprint"))}>
              <div></div>
            </div>
          </div>
        </div>
      <div className={classes.langBtn} onClick={()=>dispatch(toggleLanguage())}>{window.DB?.lang?.langBtn?.Get()}</div>

    </div>
  );
}

