import { useDispatch, useSelector } from 'react-redux';
import classes from './ContentPlayer.module.css';
import { idleOff, idleOn, setActiveContent, setApp, setLanguage, toggleLanguage } from './../../_redux/appSlice';
import { useEffect, useRef, useState } from 'react';
import { GetContentPath } from '../../App';

let to;
export default function ContentPlayer() {

  const dispatch = useDispatch();
  const app = useSelector((state) => state.app?.app);
  const [medium, setMedium] = useState(null);
  const entryId = useSelector((state) => state.app?.activeContent);
  const mainEntryId = useSelector((state) => state.app?.activeEntry);
  const language = useSelector((state) => state.app?.language);
  const ref = useRef(null);


  useEffect(() => {
    let entry = window.DB?.find(entryId);
    clearTimeout(to);
    setMedium(entry?.media[0]);
    setTimeout(()=>ref.current?.classList.remove(classes.hidden), 100);
   },[entryId, setMedium]);

   useEffect(() => {
    let entry = window.DB?.find(entryId);
    clearTimeout(to);

    if(entry?.media.length>1 && !medium?.Get().endsWith(".mp4") &&  !medium?.Get().endsWith(".webm"))
    {
      to = setTimeout(()=>{
        if(medium === entry?.media[0])
          setMedium(entry?.media[1]);
        else
          setMedium(entry?.media[0]);
      }, 5000);
    }
   },[medium, setMedium]);

   const playNext = ()=>{
    console.log("playNext", entry?.media.length, entry?.media.indexOf(medium));
    if(entry?.media.length>1){
      if(entry?.media.indexOf(medium)<entry?.media.length-1){
        setMedium(entry?.media[entry?.media.indexOf(medium)+1]);
      }else{
        //loop
        //setMedium(entry?.media[0]);
      }
    }
   }

  let entry = window.DB?.find(entryId);
  let mainEntry = window.DB?.find(mainEntryId);


  let position = "left";
  for(let i in mainEntry?.subentries){
    let mainEntrySub = mainEntry?.subentries[i];
    if(mainEntrySub.position?.x < 3)
      position = "right";
  }

  if(entry == null)
   return <></>;
  return (
    <div className={classes.root + " "+classes[position] + " " + classes.hidden} ref={ref} key={entryId}>
        <div className={classes.content}>
          {medium &&
            (medium.Get().endsWith(".mp4") || medium.Get().endsWith(".webm")?
              <video autoPlay muted playsInline className={classes.video} src={GetContentPath( medium.Get())} onEnded={()=>playNext()}></video>
            :
              <img key={ medium?.Get()} className={classes.image} src={GetContentPath( medium?.Get())}></img>
            )
          }
          {entry?.audio && <audio autoPlay playsInline className={classes.video} src={GetContentPath( entry?.audio.Get())}></audio>}
        </div>
        <div className={classes.footer}>
          <div className={classes.title +" "+entry.type}>
            <div className={classes.circle}>
              <div className={classes.icon+ " icon"} style={{'--bg':`url(/assets/icon_${entry.type}.png)`}}>
                <div></div>
              </div>
            </div>
            <div className={classes.label}>
              {entry.title.Get()}
            </div>

          </div>
          <div className={classes.closeBtn} onClick={()=>dispatch(setActiveContent(null))}
          >
            <div className={classes.circle}>
              <div className={"icon"} style={{'--bg':'url(/assets/icon_close.png)'}}></div>
            </div>
            {window.DB.lang.close.Get()}
          </div>
        </div>

    </div>
  );
}

