import { useDispatch, useSelector } from 'react-redux';
import './App.css';
import { idleOff, idleOn, setActiveContent, setActiveEntry, setApp, setDbHash, setLanguage } from './_redux/appSlice';
import { useEffect, useState } from 'react';
import Stage from './Stage/Stage';
import Footer from './Footer/Footer';
import { ParseDB } from './DB';
let idleTo;

function App() {

  const dispatch = useDispatch();
  const appDbHash = useSelector((state) => state.app?.appDbHash);
  const idleMode = useSelector((state) => state.app?.idleMode);
  const activeEntryId = useSelector((state) => state.app?.activeEntry);
  const [dimensions, setDimensions] = useState({width:1024, height:768});

  const setIdleOff = ()=>{
    clearTimeout(idleTo);

    if(!idleMode){
      clearTimeout(idleTo);
      if(window?.DB?.timeout != null){
        idleTo = setTimeout(()=>{
          if(!window.disableIdle)
            dispatch(idleOn());
        }, window?.DB?.timeout)
      }
    }

    dispatch(idleOff());
  }

  useEffect(()=>{
    if(idleMode){
      dispatch(setLanguage("de"));
      dispatch(setActiveEntry(null));
    }
  },[idleMode]);


  useEffect(()=>{
      dispatch(setActiveContent(null));
  },[activeEntryId]);


  useEffect(() => {
    document.title = "Luisenhütte";
    if(window?.DB?.timeout != null)
      setIdleOff();
  },[appDbHash]);

  useEffect(() => {
    fetch(process.env.PUBLIC_URL+"/_content/db.json")
    .then(e=>e.text())
    .then(e=>{
      try{
        return JSON.parse(e);
      }
      catch(Ex){
        console.error("Error parsing JSON", e);
      }
      return {};
    })
    .then(e=>{
      let db = ParseDB(e);
      window.DB = db;
      dispatch(setDbHash("loaded"));
      resized();
   });

   document.body.classList.add("debug");
   window.addEventListener("resize", resized);
   return ()=>{
    //document.removeEventListener("resize", resized);
   }

  },[]);


  const resized = (e)=>{
    let dimensions = document.getElementById("App")?.getBoundingClientRect() || {width:1024, height:768};
    setDimensions(dimensions);
  }

  useEffect(()=>{
    document.addEventListener("mousedown", setIdleOff);
    document.addEventListener("touchstart", setIdleOff);
    return ()=>{
      document.removeEventListener("mousedown", setIdleOff);
      document.removeEventListener("touchstart", setIdleOff);
    }
  },[setIdleOff])




  return (
    <div className="App" id="App" style={{'--stageWidth':dimensions.width+"px", '--stageHeight':dimensions.height+"px"}}>
      <Stage></Stage>
      <Footer></Footer>
      <div className="tmp" style={{'--bg':`url(/tmp/tmp__0003_Ebene.jpg)`}}>

      </div>
    </div>
  );
}

export default App;


export function GetContentPath(path){
  return process.env.PUBLIC_URL+"/_content/"+path;
}
