
let language = "de";

export function SetLanguage(lang){
  language = lang;
}

export class LanguageString{
  static __type = 'LanguageString';
  static Languages = ['de', "en", "nl"];
  constructor(rawObject){
    if(!rawObject){
      return;
    }

    this.entries = {};

    if(typeof(rawObject) === "string"){
      LanguageString.Languages.forEach(l=>{
        this._add(l, rawObject);
      })
    }
    else if(rawObject && (rawObject.__type === LanguageString.__type || rawObject.de || rawObject.en || rawObject.nl) ){
      if(rawObject.de != null)
        this._add("de", rawObject.de);
      else
        this._add("de", null);

      if(rawObject.en != null)
        this._add("en", rawObject.en);
      else
        this._add("en", null);

      if(rawObject.nl != null)
        this._add("nl", rawObject.nl);
      else
        this._add("nl", null);


      if(this.entries.de == null && this.entries.en !== null )
        this.entries.de = this.entries.en;
      else if(this.entries.en == null && this.entries.de !== null )
        this.entries.en = this.entries.de;

      if(this.entries.nl == null && this.entries.de !== null )
        this.entries.nl = this.entries.de;
    }
  }

  _add(lang, val){
    this.entries[lang] = val;
    if(IsHtml(val))
      this.isHtml = true;
  }


  Get(lang=null){
    if(lang == null)
      lang = language;

    if(!this.entries){
      //console.error("Lang entries not found", this)
      return "";
    }
    let found = this.entries[lang];
    if(found != null)
      return found;

    const keys = Object.keys(this.entries);
    if(keys.length === 0)
      return null;

    if(found == null && keys.length>0)
      found = this.entries[keys[0]];

    if(found == null && keys.length>1)
      found = this.entries[keys[1]];

    return found
  }

  GetWithValues(vals=null, lang=null){
    let str = this.Get(lang);

    if(vals == null)
      return str;

    for(let i in vals){
      str = str.replace("#"+i+"#", vals[i]);
    }


    return str;
  }
}



const htmlRegex = /<\/?[a-z][\s\S]*>/i
export function IsHtml(txt){
  return htmlRegex.test(txt);
}
