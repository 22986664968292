import { useDispatch, useSelector } from 'react-redux';
import classes from './IntroPlayer.module.css';
import { idleOff, idleOn, setActiveContent, setApp, setIntroEnded, setLanguage, toggleLanguage } from './../../_redux/appSlice';
import { useEffect } from 'react';
import { GetContentPath } from '../../App';

export default function IntroPlayer() {

  const dispatch = useDispatch();
  const app = useSelector((state) => state.app?.app);
  const entryId = useSelector((state) => state.app?.activeEntry);
  const language = useSelector((state) => state.app?.language);
  const activeSubEntryId = useSelector((state) => state.app?.activeContent);

  useEffect(() => {
    if(document.body.classList.contains("debug"))
      dispatch(setIntroEnded(true));
    else
      dispatch(setIntroEnded(false));

  },[entryId]);

  let entry = window.DB?.find(entryId);
  if(!entry && entryId != "imprint")
    entry = window.DB?.intro;
  if(entryId === "imprint"){
    entry = window.DB?.imprint;
  }

  return (
    <div className={classes.root}>
      {entryId === "imprint" && <div className={classes.imprint}>
        <img src={GetContentPath(entry.logo.Get())}></img>
        <img src={GetContentPath(entry.image.Get())}></img>
      </div>}
      <video autoPlay loop={!entry?.intro} muted playsInline className={classes.video} src={GetContentPath( entry?.intro? entry?.intro?.Get():entry?.video?.Get())} onEnded={()=>dispatch(setIntroEnded(true))}></video>
      <audio autoPlay playsInline className={classes.video} muted={activeSubEntryId!=null} src={GetContentPath( entry?.audio?.Get()||"")}></audio>
    </div>
  );
}

